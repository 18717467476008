<template>
  <v-container fluid>
    <h1 class="text-h5 font-weight-bold mb-4">Usuários</h1>

    <FilterGroup class="mb-4" :filters="types" :queryKey="'type'" />

    <FilterInput :dataFilters="[]" />

    <section class="py-4">
      <v-card class="mb-4" outlined>
        <v-data-table
          class="surface"
          :headers="headers"
          :items="customers"
          hide-default-footer
        />
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        circle
        @input="getData()"
      />
    </section>
  </v-container>
</template>

<script>
import FilterGroup from "@/components/filters/FilterGroup";
import FilterInput from "@/components/filters/FilterInput";

export default {
  data() {
    return {
      loading: false,
      types: [
        { label: "Todos", value: "" },
        { label: "Clientes", value: "customer" },
        { label: "Funcionários", value: "employee" },
      ],
      headers: [
        { text: "Nome", value: "name" },
        { text: "E-mail", value: "email" },
        { text: "Telefone", value: "phone" },
        { text: "Data de Registro", value: "createdAt" },
        { text: "Ações", value: "actions" },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        sort: "",
      },
      customers: [],
    };
  },

  components: {
    FilterGroup,
    FilterInput,
  },

  watch: {
    ["$route.query"]() {
      this.loading = true;
      this.pagination.page = 1;

      this.getData();
    },
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          sort: this.pagination.sort,
          ...this.$route.query,
        };
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>